import React from "react";
import { Helmet } from "react-helmet";
import Layout from "../components/Layout";
import styled from "styled-components";

const StyledWrapper = styled.section`
  padding: 4rem 0;

  ol {
    padding-left: 2em;
  }

  li {
    list-style-type: lower-roman;
    padding-left: 1em;
  }

  p {
    margin-bottom: 1.5em;
  }

  .row {
    margin-bottom: 4rem;
  }

  h4 {
    margin-bottom: 1em;
  }

  a {
    color: var(--blueDress);
    &:hover {
      color: var(--blueDress);
      text-decoration: underline;
    }
  }
`;
const PrivacyPolicy = () => {
  return (
    <>
      <Helmet>
        <title>Privacy Policy</title>
      </Helmet>
      <Layout>
        <StyledWrapper>
          <div className="container">
            <div className="row mb-5">
              <p className="d-flex justify-content-end">
                Last updated on 06th January, 2022
              </p>
              <h1 className="text-center">Privacy Policy</h1>
            </div>
            <div className="row">
              <p>
                <strong>Niro Capital Private Limited</strong>, CIN No.
                U65999KA2021PTC155709 (“<strong>Company</strong>”, "
                <strong>We</strong>", "<strong>Us</strong>", or "
                <strong>Our</strong>"), a private limited company with its
                registered office at #17/1, Bharat Apartments, 44/1 Fair Field
                Layout, Race Course Road, Bangalore – 560001, Karnataka, India
                which owns and operates the website www.nirocapital.com and/or the
                mobile application ‘the <strong>Niro Direct</strong>' (collectively
                referred to as the “<strong>Platform</strong>”) is committed to
                protecting Your privacy and the information that You share while
                using the Platform. We value the trust You place in Us. That’s
                why, We maintain the highest security standards for securing the
                transactions and Your information.
              </p>
              <p>
                This privacy policy (“<strong>Privacy Policy</strong>”)
                specifies the manner in which personal data and other
                information is collected, received, stored, processed,
                disclosed, transferred, dealt with or otherwise handled by the
                Company. This Privacy Policy does not apply to information that
                You provide to, or that is collected by, any third-party through
                the Platform, and any Third-Party Sites that You access or use
                in connection with the Services offered on the Platform.
              </p>
              <p>
                Please read the Privacy Policy carefully prior to using or
                registering on the Platform or accessing any material,
                information or availing any Services through the Platform.
              </p>
              <p>
                By visiting the Platform or setting up/creating an account on
                the Platform for availing the Services and clicking on the “I
                agree” button provided on the Platform, You (“
                <strong>You</strong>”, “<strong>Your</strong>” as applicable)
                accept and agree to be bound by the terms and conditions of this
                privacy policy (<strong>“Privacy Policy”</strong>). This Privacy
                Policy is incorporated into and subject to Our terms of use (
                <strong>“Terms”</strong>) and shall be read harmoniously and in
                conjunction with the Terms. All capitalized terms used and not
                defined in this Privacy Policy shall have the meaning ascribed
                to them under the Terms.
              </p>
              <p>
                This Privacy Policy (i) is an electronic record under the
                Information Technology Act, 2000 read with rules and regulations
                made thereunder and is generated by a computer system; and (ii)
                will not require any physical, electronic, or digital signature.
              </p>
            </div>

            <div className="row">
              <h4>1. Collection of Information</h4>
              <p>
                We collect Personal Information (defined below) from You when
                You register or set up an Account with Us on the Platform. You
                may browse certain sections of the Platform without being
                registered with Us. However, to avail certain Services on the
                Platform You are required to set up an Account with Us.
              </p>
              <p>This Privacy Policy applies to the following information:</p>
              <ol>
                <li>
                  <p>
                    <u>Information You give Us:</u> You may provide certain
                    information to Us voluntarily while registering on Our
                    Platform for availing Our Services, such as mobile number,
                    Permanent Account Number (PAN), signature, email address,
                    date of birth, gender, photograph (selfie), communication
                    and residential address details and proof of address,
                    location, annual income, employment type, and source of
                    income, etc. In addition to the Personal Information and in
                    order to provide Services to You, We also collect Your
                    Aadhaar, signature and undertake Your video and photo
                    verification, solely for completing the Account opening
                    procedures and authenticating Your transactions on the
                    Platform. The act of providing Your Aadhaar is voluntary in
                    nature and the Company hereby agree and acknowledge that
                    they will collect, use, and store such details in compliance
                    with applicable laws and this Privacy Policy.
                  </p>
                  <p>
                    We may also ask You for certain financial information,
                    including Your bank account details and type, Indian
                    financial system code (IFSC) of bank, and/or other payment
                    related details or other payment method data, and debit
                    instructions or other standing instructions including NACH
                    mandate to process the Services. We may ask You to provide
                    certain additional information about Yourself on a
                    case-to-case basis. All information disclosed by You shall
                    be deemed to be disclosed willingly and without any
                    coercion. No liability pertaining to the
                    authenticity/genuineness/misrepresentation/fraud/negligence
                    of the information disclosed shall lie on the Company nor
                    will the Company be in any way responsible to verify any
                    information obtained from You.
                  </p>
                  <p>
                    The above information is collectively referred to as (“
                    <strong>Personal Information</strong>”).
                  </p>
                </li>
                <li>
                  <p>
                    <u>KYC related information:</u> We may retrieve from Your
                    records available with third party including from Know Your
                    Customer (KYC) Registration Agency (KRA) such as name, KYC
                    details, KYC status, father’s/spouse’s name, occupation,
                    address details and other related documents.
                  </p>
                </li>
                <li>
                  <p>
                    <u>Location based information:</u> When and if You download
                    and/or use the Platform through Your mobile, We explicitly
                    seek permissions from You to get the required information
                    from the device. We may receive information about Your
                    location, Your IP address, and/or Your mobile device,
                    including a unique identifier number for Your device. We may
                    use this information to provide You with location-based
                    Services including but not limited to search results and
                    other personalized content. You can withdraw Your consent at
                    any time by disabling the location-tracking functions on
                    Your mobile. However, this may affect Your enjoyment of
                    certain functionalities on the Platform. In addition to the
                    above, We identify and use Your IP address to also help
                    diagnose problems with Our server, resolve such problems and
                    administer the Platform. Your IP address is also used to
                    help identify you and to gather broad demographic
                    information.
                  </p>
                </li>
                <li>
                  <p>
                    <u>Financial SMS information:</u> After obtaining Your
                    specific consent to allow the Platform to access Your SMS
                    inbox and contacts, We may collect relevant information from
                    text messages (SMS) received by You from providers of
                    services and/or products (including but not limited to
                    financial institutions, mobile carriers, and utility
                    companies) and Your contacts, that will enable Us to provide
                    better access to financial services and products to You.
                  </p>
                </li>
                <li>
                  <p>
                    <u>Information regarding installed applications:</u> We
                    collect a list of the installed applications’ metadata
                    information which includes the application name, package
                    name, installed time, updated time, version name and version
                    code of each installed application on Your device to provide
                    our Services and enrich Your profile with pre-approved
                    customized loan offers.
                  </p>
                </li>
                <li>
                  <p>
                    <u>Access to Camera and Microphone:</u> We require camera
                    access to scan and capture the required KYC documents
                    thereby allowing Us to auto-fill relevant fields. As a part
                    of facilitation of the KYC process, We require access to
                    Your camera to enable You to initiate Your KYC process. This
                    permission allows Us or Our authorized agents to perform
                    Your Video KYC while also taking screenshots of Your
                    original officially verified documents that You present
                    during Your video KYC. Video KYC enables You to complete
                    Your KYC digitally, smoothly, and efficiently. Your video
                    shall be recorded and retained for regulatory purposes along
                    with the screenshots of original official verified
                    documents. We require microphone permissions to enable a
                    two-way communication between Our authorized agent and You
                    for the purpose of performing and completing Your video KYC.
                    Your audio shall be recorded for regulatory purpose.
                  </p>
                </li>
                <li>
                  <p>
                    <u>Access to storage:</u> We require storage permission so
                    that Your KYC and other relevant documents can be securely
                    downloaded and saved on Your phone. You can then easily
                    upload the correct KYC related documents for faster loan
                    application details filling and disbursal process. This
                    ensures that You are provided with a seamless experience
                    while using the Platform.
                  </p>
                </li>
                <li>
                  <p>
                    <u>Other Information:</u> You may choose to provide Us with
                    access to certain personal information stored by third
                    parties like social networking sites (e.g., Facebook and
                    Twitter). We may access such information to the extent
                    allowed by Your privacy settings on that site and Your
                    specific authorization. In the event You associate an
                    account managed by Us with an account maintained with any
                    third party to enable Us to access certain information
                    maintained in such third-party managed accounts, You agree
                    that We may collect, store, and use such information in
                    accordance with this Privacy Policy.
                  </p>
                </li>
                <li>
                  <p>
                    <u>Non – Personal and Automatic Information:</u> We may also
                    collect certain non-personal information, such as Your
                    internet protocol address, web request, operating system,
                    browser type, URL, internet service provider, IP address,
                    aggregate user data, browser type, software and hardware
                    attributes, pages You request, and cookie information, etc.
                    which will not identify You specifically (“
                    <strong>Non - Personal Information</strong>”), while You
                    browse, access or use the Platform. We receive and store Non
                    – Personal Information by the use of data collection devices
                    such as “cookies” on certain pages of the Platform, in order
                    to help and analyze Our web - page flow, track user trends,
                    measure promotional effectiveness, and promote trust and
                    safety. We offer certain additional features on the Platform
                    that are only available through the use of a “cookie”. We
                    place both permanent and temporary cookies in Your
                    computer’s hard drive.
                  </p>
                </li>
                <li>
                  <p>
                    <u>User communications:</u> If You choose to post messages
                    on our message boards, chat rooms or other message areas or
                    leave feedback, We will collect and store such information
                    You provide to Us. We retain this information as necessary
                    to resolve disputes, provide customer support, respond to
                    queries, and inquires, and troubleshoot problems and improve
                    the Services. If You send us correspondence, such as emails
                    or letters, or if other users or third parties send us
                    correspondence about Your activities or postings on the
                    Platform, We may collect and retain such information into a
                    file specific to You for responding to Your request and
                    addressing concerns in relation to Your use of the Platform.
                  </p>
                </li>
              </ol>
              <p>
                All financial information gathered by the Company shall be
                stored on servers, log files and any other storage system owned
                by the Company or by third parties in India.
              </p>
              <p>
                Our primary goal in doing so is to provide You a safe,
                efficient, smooth, and customized experience on the Platform.
                The information collected allows Us to provide the Services and
                features on the Platform that most likely meet Your needs, and
                to customize the Platform to make Your experience safer and
                easier. More importantly, while doing so, We collect the above -
                mentioned Personal Information from You that We consider
                necessary for achieving this purpose.
              </p>
              <p>
                We also use cookies to allow You to enter Your password less
                frequently during a session on the Platform. Most cookies are
                “session cookies,” meaning that they are automatically deleted
                from Your hard drive at the end of a session. You are always
                free to decline Our cookies if Your browser permits, although in
                that case, You may not be able to use certain features or
                Services being provided on the Platform or You may be required
                to re-enter Your password each time you log – in or access the
                Platform during a session.
              </p>
              <p>
                In general, You can browse the Platform without telling Us who
                You are or revealing any Personal Information about Yourself. In
                such a case, we will only collect and store the Non – Personal
                Information. Once You give us your Personal Information, You are
                not anonymous to Us. Wherever possible, while providing the
                information to Us, We indicate which fields are mandatory and
                which fields are optional for You. You always have the option to
                not provide the Personal Information to Us through the Platform
                by choosing to not use a particular Service or feature being
                provided by Us on the Platform, which requires You to provide
                such information. We may automatically track certain information
                about You based upon Your behaviour on the Platform. We use this
                information to do internal research on Your demographics,
                interests, and behaviour to better understand, protect and serve
                You. This information is compiled and analyzed by the Company on
                an aggregated basis and not individually, in a manner that does
                not specifically identify You.
              </p>
              <p>
                We shall be entitled to retain Your Personal Information and
                other information for such duration as may be required for the
                purposes specified hereunder and will be used by Us only in
                accordance with this Privacy Policy. You acknowledge that if We
                determine that any information You have provided or uploaded
                violates the terms of this Privacy Policy, We have the right, in
                our absolute discretion, to delete or destroy such information
                without incurring any liability to You.
              </p>
            </div>
            <div className="row">
              <h4>2. Use of information:</h4>
              <p>
                We use the Personal Information, other information and
                Non-Personal Information, for the following: (i) to provide and
                improve the Services and features on the Platform that You
                request; (ii) to resolve disputes and troubleshoot problems;
                (iii) to help promote a safe service on the Platform and protect
                the security and integrity of the Platform, the Services and the
                users; (iv) to inform You about online and offline offers,
                products, services, and updates; (v) to customize Your
                experience on the Platform or share marketing material with You;
                (vi) to detect, prevent and protect Us from any errors, fraud
                and other criminal or prohibited activity on the Platform; (vii)
                to enforce and inform about our terms and conditions; (viii) to
                process and fulfil Your request for Services or respond to Your
                comments, and queries on the Platform; (ix) to contact You; (x)
                to allow Our business partners including partnered financial
                institutions and/or associates to present customized messages to
                You; (xi) to communicate important notices or changes in the
                Services provided by the Company, use of the Platform and the
                terms which govern the relationship between You and Us; and
                (xii) for any other purpose after obtaining Your consent at the
                time of collection.
              </p>
              <p>
                You also specifically agree and consent to Us collecting,
                storing, processing, transferring, and sharing information
                (including Personal Information) related to You with third
                parties such as with entities registered under applicable laws
                with payment gateways/collecting banks/KRAs etc. solely for
                providing Services to You including processing Your transaction
                requests for the Services.
              </p>
              <p>
                We may occasionally ask You to complete optional online surveys.
                These surveys may ask You for Your contact information and
                demographic information (like zip code, age, or income level).
                We use this information to tailor Your experience at the
                Platform, providing You with content that We think You might be
                interested in and to display content according to Your
                preferences. We use Your Personal Information to send You
                promotional emails, however, We will provide You the ability to
                opt-out of receiving such emails from Us. If You opt out, the
                Company may still send You non-promotional emails, such as
                emails about the Services and Your Account on the Platform.
                Unless and until, You explicitly give Your consent to Us, to do
                so, We will not share Your Personal Information with another
                user of the Platform and vice versa.
              </p>
            </div>
            <div className="row">
              <h4>3. Sharing of Information:</h4>
              <p>
                We may also disclose Your Personal Information to third party
                vendors, partnered financial institutions, consultants, and
                other service providers who work for the Company or provide
                Services through the Platform and are bound by contractual
                obligations to keep such Personal Information confidential and
                use it only for the purposes for which We disclose it to them.
                This disclosure may be required for Us, for instance, to provide
                You access to Our Services including for undertaking credit
                assessment by the partnered institutions, processing loan
                applications by the Partnered Institutions, and processing
                payments including validation of Your bank accounts and Account
                information, to facilitate and assist Our marketing and
                advertising activities/initiatives, for undertaking auditing or
                data analysis, or to prevent, detect, mitigate, and investigate
                fraudulent or illegal activities related to Our Services. We do
                not disclose Your Personal Information to third parties for
                their marketing and advertising purposes without Your explicit
                consent.
              </p>
              <p>
                The Company may disclose Your information, to the extent
                necessary: (i) to comply with applicable laws and to respond to
                lawful requests and legal process, (ii) to protect the rights
                and property of the Company, Our users, and others, including to
                enforce the Terms, and (iii) in an emergency to protect the
                personal safety and assets of the Company, the users, or any
                person. In such an event the Company is in no manner responsible
                for informing You or seeking Your approval or consent.
              </p>
              <p>
                We may, in compliance with applicable laws,
                share/transfer/assign all of Your Personal Information and other
                information with any other business entity(ies), in the event of
                a merger, sale, re-organization, amalgamation, joint ventures,
                assignment, restructuring of business or transfer or disposition
                of all or any portion of Our business.
              </p>
            </div>
            <div className="row">
              <h4>4. Security Precautions and Measures:</h4>
              <p>
                Our Platform has reasonable security measures and safeguards in
                place to protect Your privacy and Personal Information from
                loss, misuse, unauthorized access, disclosure, destruction, and
                alteration of the information in compliance with applicable
                laws. Further, whenever You change or access Your Account on the
                Platform or any information relating to it, We offer the use of
                a secure server. We cannot however ensure or warrant the
                security of any information You transmit to the Company or
                guarantee that Your Personal Information and/or other
                Non-Personal Information provided for availing the Services or
                Platform may not be accessed, disclosed, altered, or destroyed
                by a breach of any of Our security measures and safeguards. It
                is further clarified that You have and so long as You access
                and/or use the Platform (directly or indirectly) the obligation
                to ensure that You shall at all times take adequate physical,
                managerial, and technical safeguards, at Your end, to preserve
                the integrity and security of Your data which shall include and
                not be limited to Your Personal Information.
              </p>
              <p>
                This Privacy Policy and the security controls and practices
                implemented by the Company to protect Your Personal Information
                shall be the reasonable security practices and procedures under
                Section 43A of the Information Technology Act, 2000.
              </p>
            </div>
            <div className="row">
              <h4>
                5. Links to Other Third – party Sites and collection of
                information:{" "}
              </h4>
              <p>
                Our Platform may provide links to other third - party Platforms
                (“<strong>Third – Party Sites</strong>”) that may collect Your
                Personal Information including Your IP address, browser
                specification, or operating system. The Company is not in any
                manner responsible for the security of such information or their
                privacy practices or content of those Third – Party Sites.
                Additionally, You may also encounter “cookies” or other similar
                devices on certain pages of the Third – Party Sites and it is
                hereby clarified by the Company that the it does not control the
                use of cookies by these Third – Party Sites. These third-party
                service providers and Third-Party Sites may have their own
                privacy policies governing the storage and retention of Your
                information that You may be subject to. This Privacy Policy does
                not govern any information provided to, stored on, or used by
                these third-party providers and Third-Party Sites. We recommend
                that when You enter a Third-Party Site, You review the
                Third-Party Site’s privacy policy as it relates to safeguarding
                of Your information. You agree and acknowledge that We are not
                be liable for the information published in search results or by
                any Third-Party Sites.
              </p>
            </div>
            <div className="row">
              <h4>6. Public Posts: </h4>
              <p>
                You may provide Your feedback, reviews, testimonials, etc. on
                the Platform on Your use of the Services provided by the Company
                (“<strong>Posts</strong>”). Any content or Personal Information
                and Posts that You share or upload on the publicly viewable
                portion of the Platform (on discussion boards, in messages and
                chat areas, etc.) will be publicly available, and can be viewed
                by other users. Your Posts shall have to comply with the
                conditions relating to Use of Platform as mentioned in the
                Terms. The Company retains an unconditional right to remove and
                delete any Post or such part of the Post that, in the opinion of
                the Company, does not comply with the conditions in the Terms.
                The Company reserves the right to use, reproduce and share Your
                Posts for any purpose. If You delete Your Posts from the
                Platform, copies of such Posts may remain viewable in archived
                pages, or such Posts may have been copied or stored by other
                Users.
              </p>
            </div>
            <div className="row">
              <h4>7. Your Consent and Changes to Privacy Policy:</h4>
              <p>
                <strong>Withdrawal of consent:</strong> You may choose to
                withdraw Your consent provided hereunder at any point in time.
                Such withdrawal of the consent must be sent in writing to{" "}
                <a href="mailto:sankalp@niro.co.in">sankalp@niro.co.in</a>. In
                case You do not provide Your consent or later withdraw Your
                consent, We request You not to access the Platform and use the
                Services and also reserve the right to not provide You any
                Services on the Platform. In such a scenario, the Company may
                delete Your information (personal or otherwise) or de-identify
                it so that it is anonymous and not attributable to You. In the
                event, the Company retains the Personal Information post
                withdrawal or cancellation of Your consent, it shall retain it
                only for the period permitted under applicable laws.
              </p>
              <p>
                <strong>Correcting inaccuracies in the information:</strong> You
                may correct or update any information online. In the event of
                loss of access details, You may retrieve the same or receive new
                access details by sending an e-mail to:{" "}
                <a href="mailto:sankalp@niro.co.in">sankalp@niro.co.in</a>
              </p>
              <p>
                <strong>Changes To Our Privacy Policy:</strong> We reserve the
                unconditional right to change, modify, add, or remove portions
                of this Privacy Policy at any time, by notifying You of such
                changes. Any changes or updates will be effective immediately.
                You should review this Privacy Policy regularly for changes. You
                can determine if changes have been made by checking the “Last
                Updated” legend above.
              </p>
            </div>
            <div className="row">
              <h4>8. Grievance Officer:</h4>
              <p>
                If You have any grievance with respect to the Platform or the
                Services, You may write to the grievance officer, the name and
                contact details have been provided below:{" "}
              </p>
              <p>
                <strong>Name:</strong> Sankalp Mathur
                <br />
                <strong>Email:</strong>{" "}
                <a href="mailto:sankalp@niro.co.in">sankalp@niro.co.in</a>
                <br />
              </p>
            </div>
            <div className="row">
              <h4>9. Questions?</h4>
              <p>
                Please feel free to contact us at{" "}
                <a href="mailto:sankalp@niro.co.in">sankalp@niro.co.in</a>{" "}
                regarding any questions on the Privacy Policy.{" "}
              </p>
            </div>
          </div>
        </StyledWrapper>
      </Layout>
    </>
  );
};

export default PrivacyPolicy;
